import { memo, lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { TransitionGroup, CSSTransition } from "react-transition-group";

import Loading1 from "../components/custom/loading1";

const Organisations = lazy(() => import("../views/Organisations/Index"));

const Shops = lazy(() => import("../views/shops/Index"));
const ShopDashboard = lazy(() => import("../views/shops/Dashboard/index"));

const addShops = lazy(() => import("../views/shops/AddShop"));
const editShop = lazy(() =>
  import("../views/shops/Dashboard/EditBasicDetails")
);

const Category = lazy(() => import("../views/NewCategory/Index"));

const Subscription = lazy(() => import("../views/Subscription/Index"));

const UserManagment = lazy(() => import("../views/UserManagment/Index"));

const Expense = lazy(() => import("../views/Accounting/Expense/Index"));

const Income = lazy(() => import("../views/Accounting/Income/Index"));

const HrManagment = lazy(() => import("../views/StaffManagment/Index"));

const addStaff = lazy(() => import("../views/StaffManagment/Staff/AddStaff"));

const editStaff = lazy(() => import("../views/StaffManagment/Staff/EditStaff"));

const transfer = lazy(() => import("../views/Accounting/Transfer/Index"));

const newsPaper = lazy(() => import("../views/NewsPaper/Index"));

const newsChannel = lazy(() => import("../views/NewsChannel/Index"));

const settings = lazy(() => import("../views/Settings/Index"));

const balance = lazy(() => import("../views/Accounting/Balance/Index"));

const serviceLink = lazy(() => import("../views/ServiceLink/Index"));

const subscriptionTransasction = lazy(() =>
  import("../views/SubscriptionTransaction/Index")
);

const businessApproval = lazy(() => import("../views/BusinessApproval/Index"));

const DefaultRouter = memo(() => {
  return (
    <TransitionGroup>
      <CSSTransition classNames="fadein" timeout={100}>
        <Suspense fallback={<Loading1 />}>
          <Switch>
            <Route path="/dashboard" exact component={Organisations} />
            <Route path="/shops" exact component={Shops} />
            <Route
              exact
              path="/shop/:shopID/:active_tab?"
              component={ShopDashboard}
            />
            <Route path="/addshop" exact component={addShops} />
            <Route path="/shop-edit/:shopID" exact component={editShop} />
            <Route path="/categories/:active_tab?" exact component={Category} />
            <Route path="/subscription" exact component={Subscription} />
            <Route path="/userManagment" exact component={UserManagment} />
            <Route path="/accounting/expense" exact component={Expense} />
            <Route path="/accounting/income" exact component={Income} />
            <Route path="/accounting/transfer" exact component={transfer} />
            <Route path="/accounting/balance" exact component={balance} />
            <Route
              path="/humanResource/:active_tab?"
              exact
              component={HrManagment}
            />
            <Route path="/add-staff" exact component={addStaff} />
            <Route path="/edit-staff/:id" exact component={editStaff} />
            <Route path="/newspaper" exact component={newsPaper} />
            <Route path="/newschannel" exact component={newsChannel} />
            <Route path="/serviceLink" exact component={serviceLink} />
            <Route path="/settings/:active_tab?" exact component={settings} />
            <Route
              path="/subscriptiontransaction"
              exact
              component={subscriptionTransasction}
            />
            <Route
              path="/businessApproval"
              exact
              component={businessApproval}
            />
          </Switch>
        </Suspense>
      </CSSTransition>
    </TransitionGroup>
  );
});

DefaultRouter.displayName = "DefaultRouter";
export default DefaultRouter;
