import React, { memo, lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router";

import Loader from "../components/Loader";

import Default from "../layouts/dashboard/default";
import Simple from "../layouts/dashboard/simple";
import Authmiddleware from "../router/Authmiddleware";

import ScrollTop from "../components/ScrollTop";

const userRoutes = [
  { path: "/shops", component: Default },
  { path: "/", component: () => <Redirect to="/shops" /> },
 
  { path: "/addshop", component: Default },
  { path: "/shop/:shopID/:active_tab?", component: Default },
  { path: "/shop-edit/:shopID", component: Default },
  { path: "/businessApproval", component: Default },
 { path: "/categories/:active_tab?", component: Default },
  { path: "/subscription", component: Default },
  { path: "/userManagment", component: Default },
  { path: "/accounting/expense", component: Default },
  { path: "/accounting/income", component: Default },
  { path: "/humanResource/:active_tab?", component: Default },
  { path: "/add-staff", component: Default },
  { path: "/edit-staff/:id", component: Default },
  { path: "/accounting/transfer", component: Default },
  { path: "/newspaper", component: Default },
  { path: "/serviceLink", component: Default },
  { path: "/newschannel", component: Default },
  { path: "/settings/:active_tab?", component: Default },
{ path: "/accounting/balance", component: Default },
{ path: "/subscriptiontransaction", component: Default },
 {
    path: "/errors/error404",
    component: Simple,
  },
];

const authRoutes = [
  { path: "/auth", component: Simple },
  { path: "/index", component: Simple },
  { path: "/account/privacy-policy", component: Simple },
  { path: "/auth/account-delete", component: Simple },
];

const IndexRouters = memo(() => {
  return (
    <ScrollTop>
      <Switch>
        {authRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={false}
          />
        ))}
        {userRoutes.map((route, idx) => (
          <Authmiddleware
            path={route.path}
            component={route.component}
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Switch>
    </ScrollTop>
  );
});

IndexRouters.displayName = "IndexRouters";
export default IndexRouters;
