import { useState, useContext, memo, Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { useSelector } from "react-redux";
import * as SettingSelector from "../../../../store/setting/selectors";
import { useTranslation } from "react-i18next";

import RightArrow from "../../../custom/RightArrow";

import { BsFillBox2Fill, BsFillInboxesFill } from "react-icons/bs";

import { AiOutlineAppstoreAdd } from "react-icons/ai";

import { SiCodereview } from "react-icons/si";

import { MdWidgets, MdOutlineCategory } from "react-icons/md";

import { BiCategoryAlt } from "react-icons/bi";

import { GrNewWindow } from "react-icons/gr";

import accounting from "../../../../assets/images/accounting.png";
import balance from "../../../../assets/images/balance.png";
import business from "../../../../assets/images/business.png";
import Category from "../../../../assets/images/Category.png";
import channel from "../../../../assets/images/channel.png";
import Expense from "../../../../assets/images/Expense.png";
import Income from "../../../../assets/images/Income.png";
import paper from "../../../../assets/images/paper.png";
import settings from "../../../../assets/images/settings.png";
import staff from "../../../../assets/images/staff.png";
import subscription from "../../../../assets/images/subscription.png";
import transfer from "../../../../assets/images/transfer.png";
import transaction from "../../../../assets/images/transaction.png";
import users from "../../../../assets/images/users.png";

function CustomToggle({ children, eventKey, onClick }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Link
      to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className="nav-link"
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = memo(() => {
  const { t } = useTranslation();
  const [activeMenu, setActiveMenu] = useState(false);

  const [show1, setShow1] = useState(false);
  const [active, setActive] = useState("");

  let location = useLocation();
  const mode = useSelector(SettingSelector.theme_scheme_direction);
  const user = JSON.parse(localStorage.getItem("user"));
  console.log({ user });
  return (
    <Fragment className="">
      <Accordion as="ul" className="navbar-nav iq-main-menu">
        <li className="nav-item static-item"></li>
        <li
          style={
            location.pathname.includes("/shop")
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link className={`nav-link`} aria-current="page" to="/shops">
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>Businesses</Tooltip>}
            >
              <img src={business} width="14px" height="14px" />
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              Businesses
            </span>
          </Link>
        </li>
        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/humanResource/staff-list"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/humanResource/staff-list"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Staff Management</Tooltip>}
              >
                <img src={staff} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Staff Management
              </span>
            </Link>
          </li>
        ) : null}
        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/businessApproval"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/businessApproval"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Business Approval</Tooltip>}
              >
                <img src={business} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Business Approval
              </span>
            </Link>
          </li>
        ) : null}

        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname.includes("/categories")
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/categories/category"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Category</Tooltip>}
              >
                <img src={Category} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Category
              </span>
            </Link>
          </li>
        ) : null}
        <li
          style={
            location.pathname === "/userManagment"
              ? { background: "#CCDAE0", borderRadius: "6px" }
              : {}
          }
          className={` nav-item `}
        >
          <Link className={`nav-link`} aria-current="page" to="/userManagment">
            <OverlayTrigger
              placement={mode === "ltr" ? "right" : "left"}
              overlay={<Tooltip>UserManagment</Tooltip>}
            >
              <img src={users} width="14px" height="14px" />
            </OverlayTrigger>
            <span
              className="item-name text-black"
              style={{ fontWeight: "600" }}
            >
              UserManagment
            </span>
          </Link>
        </li>
        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/newschannel"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link className={`nav-link`} aria-current="page" to="/newschannel">
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>News Channel</Tooltip>}
              >
                <img src={channel} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                News Channel
              </span>
            </Link>
          </li>
        ) : null}
        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/newspaper"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link className={`nav-link`} aria-current="page" to="/newspaper">
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>News Paper</Tooltip>}
              >
                <img src={paper} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                News Paper
              </span>
            </Link>
          </li>
        ) : null}

        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/subscription"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link className={`nav-link`} aria-current="page" to="/subscription">
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Subscription</Tooltip>}
              >
                <img src={subscription} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Subscription
              </span>
            </Link>
          </li>
        ) : null}

        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/subscriptiontransaction"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/subscriptiontransaction"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Transaction</Tooltip>}
              >
                <img src={transaction} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Transaction
              </span>
            </Link>
          </li>
        ) : null}
        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname === "/serviceLink"
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/serviceLink"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Service Link</Tooltip>}
              >
                <img src={transaction} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
               Service Link
              </span>
            </Link>
          </li>
        ) : null}

        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <Accordion.Item
            as="li"
            eventKey="accounting"
            bsPrefix={`nav-item `}
            onClick={() => setActive("accounting")}
          >
            <CustomToggle
              eventKey="accounting"
              onClick={(activeKey) => setActiveMenu(activeKey)}
            >
              <OverlayTrigger
                placement={mode === "en" ? "right" : "left"}
                overlay={<Tooltip>Accounting</Tooltip>}
              >
                <img src={accounting} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Accounting
              </span>
              <i className="right-icon">
                <RightArrow />
              </i>
            </CustomToggle>
            <Accordion.Collapse eventKey="accounting">
              <ul className="sub-nav">
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/accounting/income` ||
                    location.pathname.includes("/accounting/income")
                      ? { background: "#CCDAE0", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/accounting/income">
                    <img
                      src={Income}
                      className="icon svg-icon"
                      width="14px"
                      height="14px"
                    />
                    <OverlayTrigger
                      placement={mode === "en" ? "right" : "left"}
                      overlay={<Tooltip>Income</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <img
                          src={Income}
                          className="sidenav-mini-icon"
                          width="14px"
                          height="14px"
                        />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Income
                    </span>
                  </Link>
                </Nav.Item>

                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/accounting/expense` ||
                    location.pathname.includes("/accounting/expense")
                      ? { background: "#CCDAE0", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/accounting/expense">
                    <img
                      src={Expense}
                      className="icon svg-icon"
                      width="14px"
                      height="14px"
                    />
                    <OverlayTrigger
                      placement={mode === "en" ? "right" : "left"}
                      overlay={<Tooltip>Expense</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <img src={Expense} width="14px" height="14px" />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Expense
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/accounting/transfer` ||
                    location.pathname.includes("/accounting/transfer")
                      ? { background: "#CCDAE0", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/accounting/transfer">
                    <img
                      src={transfer}
                      className="icon svg-icon"
                      width="14px"
                      height="14px"
                    />
                    <OverlayTrigger
                      placement={mode === "en" ? "right" : "left"}
                      overlay={<Tooltip>Transfer</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <img src={transfer} width="14px" height="14px" />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Transfer
                    </span>
                  </Link>
                </Nav.Item>
                <Nav.Item
                  as="li"
                  style={
                    location.pathname === `/accounting/balance` ||
                    location.pathname.includes("/accounting/balance")
                      ? { background: "#CCDAE0", borderRadius: "6px" }
                      : {}
                  }
                >
                  <Link className={` nav-link`} to="/accounting/balance">
                    <img
                      src={balance}
                      className="icon svg-icon"
                      width="14px"
                      height="14px"
                    />
                    <OverlayTrigger
                      placement={mode === "en" ? "right" : "left"}
                      overlay={<Tooltip>Balance</Tooltip>}
                    >
                      <i className="sidenav-mini-icon">
                        <img src={balance} width="14px" height="14px" />
                      </i>
                    </OverlayTrigger>
                    <span
                      className="item-name text-black"
                      style={{ fontWeight: "600" }}
                    >
                      Balance
                    </span>
                  </Link>
                </Nav.Item>
              </ul>
            </Accordion.Collapse>
          </Accordion.Item>
        ) : null}

        {user?.user_type == "super_admin" ||
        user?.user_type == "regional_admin" ? (
          <li
            style={
              location.pathname.includes("/settings")
                ? { background: "#CCDAE0", borderRadius: "6px" }
                : {}
            }
            className={` nav-item `}
          >
            <Link
              className={`nav-link`}
              aria-current="page"
              to="/settings/bankDetails"
            >
              <OverlayTrigger
                placement={mode === "ltr" ? "right" : "left"}
                overlay={<Tooltip>Settings</Tooltip>}
              >
                <img src={settings} width="14px" height="14px" />
              </OverlayTrigger>
              <span
                className="item-name text-black"
                style={{ fontWeight: "600" }}
              >
                Settings
              </span>
            </Link>
          </li>
        ) : null}
      </Accordion>
    </Fragment>
  );
});

VerticalNav.displayName = "VerticalNav";
export default VerticalNav;
