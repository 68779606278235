import React from "react";

const RightArrow = () => {
  return (
    <svg
      className="icon-18"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
};

export default RightArrow;
