import { configureStore } from "@reduxjs/toolkit";

import settingReducer from "./setting/reducers";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    setting: settingReducer,
  },
});
