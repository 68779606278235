import { memo, Fragment } from "react";
import main from "../../../assets/images/main.png";
import mini from "../../../assets/images/mini.png";
const Logo = memo((props) => {
  return (
    <Fragment>
      <div className="logo-main">
        <div className="logo-normal ms-3">
          <img width="155px" src={main} alt="" />
        </div>
        <div className="logo-mini">
          <img width="38px" src={mini} alt="" />
        </div>
      </div>
    </Fragment>
  );
});

Logo.displayName = "Logo";
export default Logo;
